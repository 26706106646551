.left-inner-addon{
    border: 1px solid #C98820;
    padding: 0.5rem;
    position: relative;
    img.thumb-search-icon{
        position: absolute;
        right: 1rem;
    }
    input[type=text] {
        width: 100%;
        box-sizing: border-box;
        padding-right: 2rem;
        outline: none;
    }
}

.form-select{
    background: transparent !important;
    padding: 0.5rem;
    border: 1px solid #C98820;
    border-radius: 0;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e') !important;
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 16px 12px !important;
    color: #000;    
}

//white-bg-grad
.bg-white-grad{
    height: 300px;
    width: 100%;
    background: url('../assets/images/white_grad_bg.png') ;
    background-size: cover;
    background-position: bottom; /*Positioning*/
    background-size: cover;
    position: absolute;    
    top: 0;
}

.bg-dark-grad{
    height: 1500px;
    width: 100%;
    background: url('../assets/images/dark_grad_bg.png') ;
    background-size: contain;
    background-position: bottom; /*Positioning*/
    position: absolute;    
    bottom: 0;
}

.sub-background-container{
    background:url('../assets/images/background_entries.png') ;
    background-size: cover;
    background-repeat: no-repeat;
}

.banner-img-container{
    width: 100%;
    background:url('../assets/images/bg_road_image.png') ;
    background-size: cover;
    background-position: center;
    position: relative;
}