//Yellow
$base-color1: #C98820;

//Header
.header{
  position: relative;
  z-index: 100;
  li.active{
    
  }
}

.icon-circle{
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
}

.bg-black{
    background-color: #000;
}

.brand-logo-footer{
    width: 200px;
    height: 100px;
}

.header{
    ul{
        list-style: none;
        li{
            color: #3f3f3f;
        }
    }
    .brand-logo img{
        width: auto;
        height: 3rem;
        margin: 1rem 0;
    }    
}

.yellow-border{
    border: 1px solid $base-color1;
    opacity: 1;
}
.text-yellow{
    color: $base-color1;
}


//Masonry Collage

.masonry {
    column-count: 2;
    column-gap: 0.5rem;
    // height: 500px;
    @media (min-width: 768px) {
      column-count: 3;
    }
    @media (min-width: 992px) {
      column-count: 4;
    }
    @media (min-width: 1199px) {
      column-count: 5;
    }
    .brick {
      box-sizing: border-box;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      counter-increment: brick-counter;
    }
    img {
      width: auto;
      height: auto;
      object-fit: cover;
    }
  }

  .dropdown-nav{
    position: absolute;
    top: 0;
    background-color: #fff !important;
    z-index: 1000;
  }

  .close-icon{
    width: 1rem;
    height: auto;
  }
  