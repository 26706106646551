
  
  // Large Laptops
  @mixin largelaptops {
    @media (min-width: 1560px) {
      @content;
    }
  }

  // Medium Laptops
  @mixin laptops {
    @media (max-width: 1440px) {
      @content;
    }
  }
  
  //Small Devices, Tablet
  @mixin small-devices {
    @media (max-width: 991px) {
      @content;
    }
  }
  @mixin tablet {
    @media (max-width: 769px) {
      @content;
    }
  }
  //Small Devices, Phones
  @mixin small-phones {
    @media (max-width: 480px) {
      @content;
    }
  }
  //Medium small Phones
  @mixin medium-small-phones {
    @media (max-width: 380px) {
      @content;
    }
  }
  // Extra small, Custom, iPhone Retina
  @mixin extra-small-phones {
    @media (max-width: 320px) {
      @content;
    }
  }
  
  @mixin media($keys...) {
    @each $key in $keys {
      @if ($key == largelaptops) {
        @include largelaptops {
          @content;
        }
      }@else if($key == laptops) {
        @include laptops {
          @content;
        }
      } @else if($key == tablet) {
        @include tablet {
          @content;
        }
      } @else if($key == small-phones) {
        @include small-phones {
          @content;
        }
      } @else if($key == medium-small-phones) {
        @include medium-small-phones {
          @content;
        }
      } @else if($key == extra-small-phones) {
        @include extra-small-phones {
          @content;
        }
      }
    }
  }
  