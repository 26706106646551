@import '../../assets/scss/mixins.scss';

.login-container{
    @include media(
        laptops
    ) {
        background-image: url(../../assets/images/maskgroup1.png);
        background-position: right top; /*Positioning*/
        background-repeat: no-repeat;
        height: 50vh;
        background-size: contain;
    }
}
.login-mob-container{
    @include media(
        tablet,
        small-phones,
        medium-small-phones,
        extra-small-phones
    ) {
        background-image: url(../../assets/images/maskgroup2.png);
        background-position: left top; /*Positioning*/
        background-repeat: no-repeat;
        background-size: contain;
        padding-top:50vh ;
        height: fit-content;
    }
}

.login-otp-container{
    @include media(
        laptops
    ) {
        background-image: url(../../assets/images/maskgroup1.png);
        background-position: right top; /*Positioning*/
        background-repeat: no-repeat;
        height: 50vh;
        background-size: contain;
    }
}
.login-otp-mob-container{
    @include media(
        tablet,
        small-phones,
        medium-small-phones,
        extra-small-phones
    ) {
        background-image: url(../../assets/images/maskgroup2.png);
        background-position: left top; /*Positioning*/
        background-repeat: no-repeat;
        background-size: contain;
        padding-top:50vh ;
        height: fit-content;
    }
}

.login-close-icon{
    @include media(
        tablet,
        small-phones,
        medium-small-phones,
        extra-small-phones
    ) {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0.5rem;
    }
}

.google-btn{
    position: relative;
    width: fit-content;
    min-width: 5rem;
    button{
        opacity: 0;
        position: absolute;
        width: 100%;
    }
    &:hover{
        button{
            opacity: 0 !important;
        }
    }
}

.otp-box{
    display: flex;
    align-items: center;
    input{
        width: 3rem;
        height: 3.5rem;
        margin: 0.5rem 0.5rem 0.5rem 0; 
        padding: 1rem;
    }
}