@import '../../assets/scss/mixins.scss';

//Yellow
$base-color1: #C98820;


.upload-banner-container{
    // height: 100vh;
    width: 100%;
    // background: url('../../assets/images/BannerImage.png'), linear-gradient(to right, #6DB3F2, #6DB3F2);
    background: url('../../assets/images/bg_print.png') ;
    background-size: cover;
    background-position: right top; /*Positioning*/
    background-color: #fff;
}

.upload-form-banner-container{
    // height: 100vh;
    width: 100%;
    // background: url('../../assets/images/BannerImage.png'), linear-gradient(to right, #6DB3F2, #6DB3F2);
    background: url('../../assets/images/bg_road_image.png') ;
    background-size: cover;
    background-position: right top; /*Positioning*/
    background-color: #fff;
}


//pink-bg-grad
.bg-pink-grad{
    @include media(
        tablet,
        small-phones,
        medium-small-phones,
        extra-small-phones
    ) {
        height: 300px;    
    }
    height: 500px;
    width: 100%;
    background: url('../../assets/images/bg_pink.png') ;
    background-position: bottom; /*Positioning*/
    background-size: contain;
    position: absolute;    
    bottom: -5rem;
}

.round-button-board{
    background-color: $base-color1;
    border: 1px dashed #000;    
    border-radius: 50%;
    border-spacing: 5px;
    outline: 5px solid #C98820;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-box{
    width:100%;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px dashed #882F29;
    border-radius: 8px;
    border-width: medium;
    background-color: #faf4eb;
}

textarea{
    .form-control{
        min-height: 200px;
    }
}

.tick-icon{
    width: 5rem;
}

.close-icon{
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    background-color: #fff;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-img-box{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 5rem;
    height: 5rem;
    background-color: #ccc;
    .text-profile{
        font-size: 1.5rem;
        height: auto;
        width: 3rem;
        height: 3rem;
        background-color: #fff;
    }
}
