@import '../../assets/scss/mixins.scss';

.vote-fixed-width{
    @include media(
        tablet,
        small-phones,
        medium-small-phones,
        extra-small-phones
    ) {
        width: auto;    
    }
    width: auto;
    margin:0 1rem;
}

.leadership-container{
 margin: 3rem 0;
}

.card-bgimg{
    background: url('../../assets/images/mask_bg_group.png') ;
    background-size: cover;
    background-position: right top; /*Positioning*/
    background-color: #fff;
    // outline: 1px solid #C98820;
    outline-offset: 5px;
    .box-img{
        min-height: 8rem;
        height: 8rem;    
        background-color: #ccc;
        object-fit: cover;
    }
}

.img-box{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 8rem;
    height: 8rem;
    background-color: #ccc;
    .text-profile{
        font-size: 1.5rem;
        height: auto;
        width: 3rem;
        height: 3rem;
        background-color: #fff;
    }
}

.point-box{
    line-height: 0.5rem;
    .text-grey{
        color: #9A9A9A;
        font-size: 0.8rem;
    }
}