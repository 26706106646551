@import '../../assets/scss/mixins.scss';
//Yellow
$base-color1: #C98820;


body{
    background: #0b1d26 ;
}

//Container 1
.background-fit-container{
    // height: 100vh;
    width: 100%;
    // background: url('../../assets/images/BannerImage.png'), linear-gradient(to right, #6DB3F2, #6DB3F2);
    background:url('../../assets/images/image_2084.png') ;
    background-size: cover;
    background-color: #fff;
    position: relative;
}

//Container UserHome
.background-fit-user-container{
    width: 100%;
    height: 100vh;
    // background: url('../../assets/images/BannerImage.png'), linear-gradient(to right, #6DB3F2, #6DB3F2);
    background:url('../../assets/images/bg_thank_card.png') ;
    background-size: cover;
    background-color: #fff;
    background-position: center;
    position: relative;
}

//Container 2
.background-fit-container2{
    // height: 100vh;
    width: 100%;
    // background: url('../../assets/images/BannerImage.png'), linear-gradient(to right, #6DB3F2, #6DB3F2);
    background: url('../../assets/images/mask_bg_group.png') ;
    background-size: cover;
    background-position: right top; /*Positioning*/
    background-color: #fff;
    background-size: contain;
    position: relative;
}

.banner-fit-div{
    @include media(
        tablet,
        small-phones,
        medium-small-phones,
        extra-small-phones
    ) {
        h5{
            font-size: 1rem;
        }
    } 
}

.media-box-100{
    height: 100vh;
    position: relative;
    z-index: 100;
    @include media(
        tablet,
        small-phones,
        medium-small-phones,
        extra-small-phones
    ) {
        height: auto;
    }
}

//Leaderboard Background
.print-bg{
    // height: 100vh;
    width: 100%;
    // background: url('../../assets/images/BannerImage.png'), linear-gradient(to right, #6DB3F2, #6DB3F2);
    background:url('../../assets/images/bg_print.png') ;
    background-size: cover;
    background-color: #fff;
    position: relative;
}

//white-bg-grad
.bg-white-grad{
    height: 300px;
    width: 100%;
    background: url('../../assets/images/white_grad_bg.png') ;
    background-size: contain;
    background-position: bottom; /*Positioning*/
    position: absolute;    
    top: 0;
}

.bg-dark-grad{
    height: 500px;
    width: 100%;
    background: url('../../assets/images/dark_grad_bg.png') ;
    background-size: contain;
    background-position: bottom; /*Positioning*/
    position: absolute;    
    bottom: 0;
}

.bg-print-grad{
    height: 500px;
    width: 100%;
    background: url('../../assets/images/home_banner.png') ;
    background-size: contain;
    background-position: bottom; /*Positioning*/
    position: absolute;    
    bottom: -6rem;
    @include media(
        tablet,
        small-phones,
        medium-small-phones,
        extra-small-phones
    ) {
        background-size:cover;
        height: 600px;
    }
}

.vote-content-box{
    padding: 8rem 0;
}

.sub-background-container{
    // background: url('../../assets/images/rectangle03758.png'), url('../../assets/images/Bgimage2080.png') ;
    // background: linear-gradient(180deg, transparent 43.3333%,#0b1d26 56.6667%,), url('../../assets/images/Bgimage2080.png') ;
    background:url('../../assets/images/Bgimage2080.png') ;
    background-size: cover;
    background-repeat: no-repeat;
}

// .bg-image-mountain{
//     background: url('../../assets/images/rectangle3714.png') ;
//     height: 500px;
//     position: absolute;
//     z-index: -1;
//     width: 100%;
// }


.trending-tag{
    background-color: #C98820;
    color: #fff;
    border-radius: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    bottom: 5rem;
    margin: 0 3rem;
}

.yellow-border-bottom{
    border-bottom: 1px solid $base-color1;
    opacity: 1;
}

.banner-sub-text{
    text-transform: capitalize;
}

.picturebox-container{
    margin: 3rem 0;
    // img{
    //     width: 15rem;
    //     height: auto;
    // }
    h2{
        line-height: 3rem;
    }
    .yellow-border{
        border-bottom: 1px solid $base-color1;
        opacity: 1;
    }
    .text-yellow{
        color: $base-color1;
    }
    p{
        font-size: 1.2rem;
        line-height: 2rem;
    }
}

.card-box{
    position: relative;
    padding: 1rem;
}
    
.round-board{
    
    // background-color: $base-color1;
    // border: 1px dashed #000;    
    // border-radius: 50%;
    // border-spacing: 5px;
    // outline: 15px solid #C98820;
    width: 3rem;
    height: 3rem;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

.card-box-img{
    @include media(
        tablet,
        small-phones,
        medium-small-phones,
        extra-small-phones
    ) {
        // max-height:30vh;
        width: 100%;
        object-fit: cover;
  }
}


.search-box{
    background-color: #fff;
    padding: 0.1rem;
    border-radius: 0.5px;
    input{
        height: 4rem;
        padding: 0.5rem 1rem;
    }
    button{
        height: 4rem;
        min-width: 8rem;
        background-color:#882F29;
        border:0;
        color: #fff;
        border-radius: 0.2rem;
    }
}

.thumb-icon{
    min-width: 2.5rem !important;
    width: 2.5rem !important;
    height: auto;
}

.yellow-border-light{
    border:1px solid #C98820;
    opacity: .3;
}

.custom-accordion{
    button{
        background: transparent !important;
        border: 0 !important;
        box-shadow: none !important;
        color: #000;
        padding: 0;
    }
    .accordion-button:not(.collapsed){
        color: #414141 !important;
        &.active-icon{
            color: #C98820 !important;
        }
    }
    .accordion-button:after{
        display: none;
    }
}

.vote-list{
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    height: 500px;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        opacity: 0;
    }
}


// /* Optional: show position indicator in red */
// ::-webkit-scrollbar-thumb {
//     background: #FF0000;
// }

.arrow-button{
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $base-color1;
}

.arrow-btn{
    width: auto;
    height: 1.5rem;
}

.registration-box{
    .registration-txt{
        font-size: 1rem;
    }
}

.round-box{
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul.nav-tabs{
    display: none;
}

.border-dummy{
    border-bottom:1px solid #E5DAC8;
}

.btn-tabs{
    border: 0;
    outline: none;
    padding: 2rem;
    color: #414141;
    &.active{
        border-bottom:2px solid #882F29;
    }
}

  .leaderbar-card{
    height: 4rem;
    align-items: center;
    background: #fff;
    margin: 1rem !important;
    border-radius: 10px;
  }

  .diamond-square{
    width: 2rem !important;
    height: 2rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -0.5rem;
    background-color: #DF8900;
    border: 1px solid #fff;
    /* Safari */
    -webkit-transform: rotate(-45deg);

    /* Firefox */
    -moz-transform: rotate(-45deg);

    /* IE */
    -ms-transform: rotate(-45deg);

    /* Opera */
    -o-transform: rotate(-45deg);
    p{
        -webkit-transform: rotate(45deg);

        /* Firefox */
        -moz-transform: rotate(45deg);

        /* IE */
        -ms-transform: rotate(45deg);

        /* Opera */
        -o-transform: rotate(45deg);
    }
  }

  .profile-thumb{
    width: 2.5rem;
    height: auto;
  }

  .profile-pic{
    width: 5rem;
    height: 5rem;
    object-fit: cover;
  }

  .faq-box,.single-picture-box{
    padding: 4rem 0;
  }


  .profilebox{
    width: 5rem;
    height: 5rem;
    object-fit: cover;
  }

  body{
    @include media(
        largelaptops
        ) {
            .partition-1{
                padding: 1rem 0;
            }
            .partition-3{
                padding: 1rem 0;
            }
        }
  }
  