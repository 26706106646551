@font-face {
    font-family: 'Futura-regular';
    src: url(../fonts/futura/futur.ttf);
}

@font-face {
    font-family: 'Futura-light';
    src: url(../fonts/FutursPT/FutursPT/FuturaPTLight/FuturaPTLight.otf);
}

@font-face {
    font-family: 'Futura-Bold';
    src: url(../fonts/FutursPT/FutursPT/FuturaPTBold/FuturaPTBold.otf);
}

@font-face {
    font-family: 'Futura-Extra-Black';
    src: url(../fonts/FutursPT/FutursPT/FuturaPTExtraBold/FuturaPTExtraBold.otf);
}

@font-face {
    font-family: 'Futura-Heavy';
    src: url(../fonts/FutursPT/FutursPT/FuturaPTDemi/FuturaPTDemi.otf);
}

@font-face {
    font-family: 'Futura-Medium';
    src: url(../fonts/futura/futura_medium_condensed_bt.ttf);
}
// futura_medium_condensed bt.ttf
// futura medium bt.ttf
// futura_medium_bt.ttf
// USAGE
body,* {
    font-family: 'Futura-Heavy';
    h1{
        font-family: 'Futura-Extra-Black';
        font-style: normal;
        font-weight: bold;
    }
    h2{
        font-family: 'Futura-Heavy';
        font-weight: bold;
    }
    h3,h4{
        font-family: 'Futura-Bold';
        font-weight: bold;
    }
    h5,h6,p{
        font-family: 'Futura-Heavy';
        font-weight: normal;
    }
    li,a,button{
        font-family: 'Futura-Heavy';
        font-weight: normal;
    }
    .font-futura-bold{
        font-family: 'Futura-Extra-Black';
    }
}
