@import '../assets/scss/mixins.scss';

.print-bg{
    // height: 100vh;
    width: 100%;
    // background: url('../../assets/images/BannerImage.png'), linear-gradient(to right, #6DB3F2, #6DB3F2);
    background:url('../assets/images/bg_print.png') ;
    background-size: cover;
    background-color: #fff;
    position: relative;
}

.bg-thankimage-container{
    background:url('../assets/images/bg_thank_card.png') ;
    background-size: cover;
    background-position: center;
}

// .row{
//     // @include media(
//     //     tablet,
//     //     small-phones,
//     //     medium-small-phones,
//     //     extra-small-phones
//     // ) {
//     //     flex-direction: column-reverse !important;
//     // }
// }

.share-box{
    @include media(
        tablet,
        small-phones,
        medium-small-phones,
        extra-small-phones
    ) {
        text-align: center;
    .icon-list   {
        display: flex;
        justify-content: center;
    }
    }
}