.container {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  .masonry {
    column-count: 2;
    column-gap: 5px;
    row-gap:1rem;
    @media (max-width: 480px) {
      column-count: 1;
    }
    @media (min-width: 768px) {
      column-count: 2;
    }
    @media (min-width: 992px) {
      column-count: 2;
    }
    @media (min-width: 1199px) {
      column-count: 2;
    }
    .brick {
      box-sizing: border-box;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      counter-increment: brick-counter;
      margin: 1rem 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .tape-img{
    display: flex;
    width: 100px !important;
    height: auto !important;
    margin: auto;
    position: relative;
    bottom: 1rem;
  }

  .bg-paper{
    background-image: url(../../assets/images/bg_paper.png);
    background-position: bottom;
    background-size: cover;
    position: relative;
    top: -0.5rem;
    width: 100%;
    height: fit-content;
  }