@import './function.scss';
@import '../fonts/fonts.scss';
@import './mixins.scss';

//Yellow
$base-color1: #C98820;

body{
    padding-right: 0 !important;
}

input {
    .form-control{
        min-height: 41px;
        padding: 0.5rem;
        font-size: 1rem;
    }
}

.srv-validation-message{
 color: #d14036;   
}

.cursor-pointer{
    cursor: pointer;
}
.dropdown-toggle::after{
    display: none;
}

.custom-dropdown{
    .dropdown-menu{
        border-radius: 0;
        padding: 0.5rem;
        .dropdown-item{
            border-bottom: 1px solid #C98820;
        }
        .dropdown-item:nth-last-child(1){
            border-bottom: 0;
        }
    }
    button{
        padding: 0;
        outline: none;
        border: 0;
    }
    &:after{
        opacity: 0 !important;
    }
}

.text-black{
    color: #2B2B2B !important;
}

.bg-primarycolor{
    background: white ;
}

.btn-submit{
    height: 41px;
    min-width: 8rem;
    background-color:#811f18;
    border:0;
    color: #fff;
    border-radius: 0.2rem;
    &.btn-disabled{
        background-color:#C5C5C5;
        color: #fff;
        &:hover{
            background-color:#e9ecef;
            color: #fff;
        }
    }
    &:disabled{
        background-color: #C5C5C5;
        &:hover{
            background-color:#e9ecef;
            color: #fff;
        }
    }
    &:hover{
        background-color:#d14036;
        color: #fff;
    }
    @include media(
        tablet,
        small-phones,
        medium-small-phones,
        extra-small-phones
    ) {
        margin: auto;
    }
}

textarea{
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #868383;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: #868383;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: #868383;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: #868383;
      }
}

.border-black{
    border:1px solid #000
}

.border-bottom-black{
    border-bottom:1px solid #000
}

.border-white{
    border:1px solid #fff
}

a{
    text-decoration: none;
    color: #000;
    &:hover{
        color: $base-color1;
        border-bottom: 1px solid $base-color1;
    }
    &.active{
        color: $base-color1;
        border-bottom: 1px solid $base-color1;
    }
}

.h4{
    font-family: "Futura-Bold";
    font-weight: bold;
    font-size: calc(1.275rem + 0.3vw);
}

.row{
    margin: 0;
    padding: 0;
}

.fw-bolder{
    font-weight: bolder;
}

.whitespace-nowrap{
    white-space: nowrap;
}

.modal{
    background:url('../../assets/images/bg_print.png') !important ;
    background-color: #ffffffad !important;
    @include media(
        tablet,
        small-phones,
        medium-small-phones,
        extra-small-phones
    ) {
        overflow-y: scroll !important;
    }
}

.text-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 8rem;
    white-space: nowrap;
}

.position-relative{
    position: relative;
    z-index: 10;
}

.disabled{
    background-color: #e9ecef;
}

body{
    @include media(
        largelaptops
    ) {
        h1{
        font-size: 4rem;
        }
        p, a, button{
            font-size: 1.5rem;
            height: auto;
        }
        h5{
            font-size: 2rem;
        }
        .btn-submit{
            &:disabled{
                background-color: #C5C5C5;
            }
            padding: 1rem 0;
            height: fit-content;
        }
    }
}

.footer-icons{
    text-decoration: none;
    border-bottom: 0;
    &:hover{
        text-decoration: none !important;
        border-bottom: 0;
    }
}

